<template>
  <ProfileCard
    data-test="myprofile-profile-user-info"
    :title="i18n(translations.title)"
    @edit="openModal(MODAL_NAMES.KYC.PROFILE_INFO_EDIT)"
  >
    <div class="flex items-center">
      <div class="body-1">
        <div class="w-[20ch] truncate lg:w-[40ch]">{{ fullName }}</div>
        <div class="w-[20ch] truncate lg:w-[40ch]">
          {{ profileData.user.username }}
        </div>
        <div>{{ phone }}</div>

        <div>
          <RevLink
            v-if="profileData.user.usablePassword !== false"
            class="my-7"
            @click="openModal(MODAL_NAMES.KYC.PROFILE_PASSWORD_EDIT)"
          >
            {{ i18n(translations.updatePassword) }}
          </RevLink>
        </div>
        <div>
          <RevLink
            :to="{
              name: ROUTE_NAMES.DASHBOARD.KYC.DELETE_ACCOUNT,
            }"
          >
            {{ i18n(translations.deleteAccount) }}
          </RevLink>
        </div>
      </div>
    </div>

    <InfoEditModal
      :profile-data="profileData"
      @update-profile-info="loadProfileData"
    />
    <PasswordEditModal />
  </ProfileCard>
</template>

<script setup lang="ts">
import { type Ref, ref } from 'vue'

import { getProfile } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/profile'
import type { RetrieveClientProfileData } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/types'
import { getFullName } from '@backmarket/nuxt-module-address/utils'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import parsePhoneNumber from 'libphonenumber-js'

import { MODAL_NAMES, ROUTE_NAMES } from '~/scopes/dashboard/constants'

import InfoEditModal from '../InfoEditModal/InfoEditModal.vue'
import PasswordEditModal from '../PasswordEditModal/PasswordEditModal.vue'
import ProfileCard from '../ProfileCard/ProfileCard.vue'

import translations from './UserInfoCard.translations'

const { market } = useMarketplace()
const i18n = useI18n()
const { openErrorToast } = useTheToast()

const profileData: Ref<RetrieveClientProfileData> = ref({
  user: {
    id: -1,
    firstName: '',
    lastName: '',
    username: '',
    usablePassword: false,
    accountCreationDate: '',
  },
  emarsysID: '',
  encryptedUserId: '',
  countryDialInCode: '',
  phone: '',
})
const fullName = ref('')
const phone = ref('')

const loadProfileData = async () => {
  profileData.value = await $httpFetch(getProfile)
  fullName.value = getFullName(
    market.countryCode,
    profileData.value.user.firstName,
    profileData.value.user.lastName,
  )

  phone.value = [
    profileData.value.countryDialInCode,
    profileData.value.phone,
  ].join(' ')
  const phoneNumber = parsePhoneNumber(phone.value)
  if (phoneNumber) {
    phone.value = phoneNumber.formatInternational()
  }
}

try {
  await loadProfileData()
} catch (err) {
  openErrorToast()
}
</script>
