export default {
  title: {
    id: 'myprofile_edit_modal_title',
    defaultMessage: 'Personal Information',
  },
  email: {
    id: 'myprofile_edit_fields_email_label',
    defaultMessage: 'Email*',
  },
  firstName: {
    id: 'myprofile_edit_fields_first_name_label',
    defaultMessage: 'First name',
  },
  katakanaFirstName: {
    id: 'myprofile_edit_fields_katakana_first_name_label',
    defaultMessage: 'First name (Kana - Optional)',
  },
  invalidEmail: {
    id: 'input_email_invalid',
    defaultMessage: 'This email seems to be invalid',
  },
  invalidLength: {
    id: 'myprofile_edit_fields_invalid_length',
    defaultMessage: 'This field is too long',
  },
  invalidPhone: {
    id: 'myprofile_phone_invalid',
    defaultMessage:
      "Hmm, that number doesn't look quite right. Please make sure its format is correct.",
  },
  lastName: {
    id: 'myprofile_edit_fields_last_name_label',
    defaultMessage: 'Last name',
  },
  katakanaLastName: {
    id: 'myprofile_edit_fields_katakana_last_name_label',
    defaultMessage: 'Surname (Kana - Optional)',
  },
  phoneNumber: {
    id: 'myprofile_edit_fields_phone_number_label_optional',
    defaultMessage: 'Phone number - optional',
  },
  phoneNumberInputClear: {
    id: 'myprofile_edit_fields_phone_number_input_clear_aria_label',
    defaultMessage: 'Clear',
  },
  required: {
    id: 'form_rules_required',
    defaultMessage: 'This field is required',
  },
  genericError: {
    id: 'myprofile_edit_form_generic_error',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  updateInfoSuccess: {
    id: 'myprofile_edit_success',
    defaultMessage: 'Your information has been updated successfully.',
  },
  save: {
    id: 'myprofile_button_save',
    defaultMessage: 'Save',
  },
}
