import { onErrorCaptured } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

/**
 * Adds additional information to errors and logs them
 */
export function useLogCapturedError() {
  const logger = useLogger()

  onErrorCaptured((error, instance, info) => {
    // eslint-disable-next-line no-underscore-dangle
    const componentName = instance?.$options?.__name || 'none'
    logger.error(error.message, {
      componentName,
      info,
      error,
    })

    return true
  })
}
