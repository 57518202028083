<template>
  <ProfileCard
    v-if="emailPreferencesList?.length"
    :showEditButton="false"
    :title="i18n(translations.title)"
  >
    <fieldset class="flex flex-col gap-12">
      <legend :aria-label="i18n(translations.title)" />

      <RevToggle
        v-for="{ name, optin, label } in emailPreferencesList"
        :key="name"
        :checked="optin"
        data-test="myprofile-preferences-switch"
        :label
        @update:model-value="
          (value) => updatePreference({ name, optin: value })
        "
      />
    </fieldset>
  </ProfileCard>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import {
  type NewsletterPreference,
  getNewsletterPreferences,
  patchNewsletterPreferences,
} from '@backmarket/http-api/src/api-specs-crm-service/crm-service'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import { RevToggle } from '@ds/components/Toggle'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import ProfileCard from '../ProfileCard/ProfileCard.vue'

import { EVENT, NEWSLETTERS_NAMES } from './NewsletterPreferencesCard.constants'
import translations from './NewsletterPreferencesCard.translations'

const route = useRoute()
const i18n = useI18n()
const { openErrorToast } = useTheToast()
const { trackClick } = useTracking()

const { data } = useHttpFetch(getNewsletterPreferences)

const preferenceLabel = {
  [NEWSLETTERS_NAMES.goodDeals]: i18n(translations.goodDeals),
  [NEWSLETTERS_NAMES.sletter]: i18n(translations.sletter),
}

const emailPreferencesList = computed(() =>
  data.value?.map((preference) => ({
    ...preference,
    label: preferenceLabel[preference.name] || preference.name,
  })),
)

function updatePreference(preference: NewsletterPreference) {
  useDebounceFn(async () => {
    trackClick({
      name: preference.optin ? EVENT.ENABLE_EMAIL : EVENT.DISABLE_EMAIL,
      zone: preference.name,
      pageType: TRACKING_PAGE_TYPE_BY_ROUTE_NAME[String(route.name)],
    })

    try {
      await $httpFetch(patchNewsletterPreferences, { body: preference })
    } catch {
      openErrorToast()
    }
  }, 500)()
}
</script>
