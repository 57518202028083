<template>
  <div>
    <RevForm
      class="flex h-full flex-col justify-between space-y-16"
      data-test="myprofile-address-form"
      :initial-values="initialValues"
      :validate="validate"
      @submit="submit"
    >
      <template #default="{ values, errors, hasError }">
        <AddressFields
          v-if="props.showPhone"
          v-model:city="values.city"
          v-model:company="values.company"
          v-model:country="values.country"
          v-model:first-name="values.firstName"
          v-model:first-name-pronunciation="values.firstNamePronunciation"
          v-model:last-name="values.lastName"
          v-model:last-name-pronunciation="values.lastNamePronunciation"
          v-model:phone="values.phone"
          v-model:postal-code="values.postalCode"
          v-model:state-or-province="values.stateOrProvince"
          v-model:street="values.street"
          v-model:street2="values.street2"
          :autocomplete-max-items="3"
          :countries="props.countryOptions"
          :default-country="countryCode"
          :errors="allErrors(errors)"
          :featureCode="FeatureCode.WEB_CUSTOMER_UPDATE_ADDRESS"
          :phone-help-message="i18n(translations.deliveryPhoneHelp)"
        />
        <AddressFields
          v-if="!props.showPhone"
          v-model:city="values.city"
          v-model:company="values.company"
          v-model:country="values.country"
          v-model:first-name="values.firstName"
          v-model:first-name-pronunciation="values.firstNamePronunciation"
          v-model:last-name="values.lastName"
          v-model:last-name-pronunciation="values.lastNamePronunciation"
          v-model:postal-code="values.postalCode"
          v-model:state-or-province="values.stateOrProvince"
          v-model:street="values.street"
          v-model:street2="values.street2"
          :autocomplete-max-items="3"
          :countries="props.countryOptions"
          :default-country="countryCode"
          :errors="allErrors(errors)"
          :featureCode="FeatureCode.WEB_CUSTOMER_UPDATE_ADDRESS"
        />

        <RevButton
          :disabled="isLoading || hasError"
          full-width="always"
          :loading="isLoading"
          type="submit"
          variant="primary"
        >
          {{ i18n(translations.save) }}
        </RevButton>
      </template>
    </RevForm>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

import { Country } from '@backmarket/http-api'
import type {
  RetrieveClientBillingAddressData,
  RetrieveClientShippingAddressData,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/client/types'
import AddressFields from '@backmarket/nuxt-module-address/AddressFields.vue'
import { AddressFieldsValidators } from '@backmarket/nuxt-module-address/AddressFieldsValidators'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { RevButton } from '@ds/components/Button'
import { RevForm, makeValidate } from '@ds/components/Form'
import { getPhoneNumberToE164 } from '@ds/components/InputPhone'

import translations from './AddressForm.translations'

const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()

const props = defineProps<{
  address:
    | RetrieveClientBillingAddressData
    | RetrieveClientShippingAddressData
    | null
  apiErrors: { [x: string]: string }
  countryOptions: {
    label: string
    value: string
  }[]
  isLoading: boolean
  showPhone?: boolean
}>()

const i18n = useI18n()

const countryCode: Country =
  (props.address?.country as Country) ||
  (useMarketplace().market.countryCode as unknown as Country)

function getPhoneData(
  address: RetrieveClientShippingAddressData,
): string | undefined {
  return address?.phone && address?.countryDialInCode
    ? getPhoneNumberToE164(address.countryDialInCode, address.phone)
    : undefined
}

const initialValues = reactive<FormValues>({
  city: props.address?.city || '',
  company: props.address?.company || '',
  country: (props.address?.country as Country) || '',
  firstName: props.address?.firstName || '',
  lastName: props.address?.lastName || '',
  postalCode: props.address?.postalCode || '',
  stateOrProvince: props.address?.stateOrProvince || '',
  street: props.address?.street || '',
  street2: props.address?.street2 || '',
  ...insertIf(props.address?.country === Country.JP, {
    firstNamePronunciation: props.address?.firstNamePronunciation || '',
    lastNamePronunciation: props.address?.lastNamePronunciation || '',
  }),
  ...insertIf(props.showPhone, {
    phone:
      getPhoneData(props.address as RetrieveClientShippingAddressData) || '',
  }),
})

export type FormValues = {
  city: string
  company: string
  country: Country
  firstName: string
  lastName: string
  nationalId?: string
  postalCode: string
  stateOrProvince: string
  phone?: string
  street: string
  street2: string
  birthdate?: string
  firstNamePronunciation?: string
  lastNamePronunciation?: string
}

const emit = defineEmits<{ submit: [values: FormValues] }>()

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_REGISTER_FORM',
  initialFormValues: initialValues,
  metadata: {
    scope: 'customer',
  },
  fallbackValidators: AddressFieldsValidators(i18n),
})

const validate = computed(() => {
  return makeValidate<FormValues>(addressValidation)
})

function allErrors(errors: Partial<Record<keyof FormValues, string>>) {
  return { ...errors, ...props.apiErrors }
}

function submit(values: FormValues) {
  emit('submit', values)
}
</script>
