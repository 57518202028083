<template>
  <ProfileCard
    data-qa="billing-address-card"
    :title="i18n(translations.header)"
    @edit="openModal(modalName)"
  >
    <AddressComponent
      v-if="hasAddress"
      :address="displayAddress"
      localize-name-order
      with-details
    />
    <p v-else data-test="empty-state">
      {{ i18n(translations.emptyAddress) }}
    </p>
  </ProfileCard>
  <RevModal
    :name="modalName"
    :title="i18n(translations.modalTitle)"
    variant="confirmation"
    @close="resetValidationErrors"
  >
    <template #body>
      <AddressForm
        :address="addressData"
        :api-errors="apiErrors"
        :country-options="countryOptions"
        :is-loading="isLoading"
        @submit="updateAddress"
      />
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { useAsyncData } from '#imports'
import { computed, ref } from 'vue'

import {
  getBillingAddress,
  postBillingAddress,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/client/billing-address'
import type { RetrieveClientBillingAddressData } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/types'
import AddressComponent from '@backmarket/nuxt-module-address/Address.vue'
import type { Address } from '@backmarket/nuxt-module-address/address'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { RevModal } from '@ds/components/Modal'
import { closeModal, openModal } from '@ds/components/ModalBase'

import ProfileCard from '../../ProfileCard/ProfileCard.vue'
import AddressForm, {
  type FormValues,
} from '../components/AddressForm/AddressForm.vue'
import { useMarketplaceCountryCodes } from '../composables/useMarketplaceCountryCodes'
import { useValidationErrors } from '../composables/useValidationErrors'
import { addressIsEmpty } from '../utils/addressIsEmpty'
import { isValidationError } from '../utils/isValidationError'

import translations from './BillingAddressCard.translations'

const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()

const modalName = 'PROFILE_BILLING_ADDRESS'

const apiErrors = ref<{ [key: string]: string }>({})
const addressData = ref<RetrieveClientBillingAddressData | null>(null)
const isLoading = ref<boolean>(false)

isLoading.value = true
const response = await useAsyncData('billingAddress', async () => {
  return $httpFetch(getBillingAddress)
})
isLoading.value = false
if (response.data.value || response.error.value?.statusCode === 404) {
  addressData.value = response.data.value || null
} else if (response.error.value instanceof Error) {
  openErrorToast()
}

const hasAddress = computed(() => !addressIsEmpty(addressData.value))
const displayAddress = computed(() => addressData.value as unknown as Address)

type CountryOption = { label: string; value: string }
const countryOptions = computed<CountryOption[]>(() => {
  return addressData.value?.availableCountries
    ? addressData.value.availableCountries
        .map(
          ({ country_code: value, name }) =>
            ({
              label: (value && i18n.country(value)) || name,
              value,
            }) as CountryOption,
        )
        .sort((a, b) => a.label.localeCompare(b.label))
    : useMarketplaceCountryCodes()
})

function transformRequestData(formValues: FormValues): Record<string, string> {
  return removeEmptyValuesInObject(formValues) as Record<string, string>
}

function resetValidationErrors(): void {
  apiErrors.value = {}
}

// eslint-disable-next-line consistent-return
async function updateAddress(formValues: FormValues) {
  resetValidationErrors()
  isLoading.value = true

  try {
    await $httpFetch(postBillingAddress, {
      body: transformRequestData(formValues),
    })
    openSuccessToast({ title: i18n(translations.updateSuccess) })
    addressData.value = { ...addressData.value, ...formValues }
    closeModal(modalName)
  } catch (error) {
    if (isValidationError(error)) {
      apiErrors.value = useValidationErrors(error.errors, {
        POSTAL_CODE_INVALID: translations.validationErrorPostalCodeInvalid,
        COUNTRY_DIAL_IN_CODE_PHONE_NUMBER_MISMATCH:
          translations.validationErrorPhoneNumberMismatch,
      })
    } else {
      openErrorToast()
    }
  } finally {
    isLoading.value = false
  }
}
</script>
