export default {
  title: {
    id: 'myprofile_infos_title',
    defaultMessage: 'My Info',
  },
  updatePassword: {
    id: 'myprofile_infos_modify_password',
    defaultMessage: 'Change my password',
  },
  deleteAccount: {
    id: 'myprofile_infos_delete_account',
    defaultMessage: 'Delete your account',
  },
}
