<template>
  <RevCard class="flex gap-16 px-16 py-24">
    <div class="flex-1">
      <h2 class="heading-2 pb-12">
        {{ props.title }}
      </h2>
      <slot />
    </div>
    <div v-if="props.showEditButton" class="flex-initial">
      <RevButtonTiny
        data-qa="edit-button"
        :loading="isLoading"
        variant="secondary"
        @click="onClick"
        >{{ i18n(translations.edit) }}</RevButtonTiny
      >
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'

import translations from './ProfileCard.translations'

const props = withDefaults(
  defineProps<{
    title: string
    showEditButton?: boolean
    isLoading?: boolean
  }>(),
  {
    showEditButton: true,
    isLoading: false,
  },
)

const i18n = useI18n()

const emit = defineEmits(['edit'])

const onClick = () => {
  emit('edit')
}
</script>
